import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

import SEO from "../components/seo"

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  // const iconSize = 20
  const cardText = "James Shapiro"

  return (
    <div className="og-social-card">
      <SEO title="social-card" />
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={"James"}
        style={{
          minWidth: 50,
          //   borderRadius: `100%`,
          display: "block",
          float: "left",
          margin: "75px 0 0 20px",
          zIndex: "100",
        }}
      />
      <h2
        style={{
          color: "#3D608D",
          margin: "120px 20px 0px 580px",
          fontSize: "135px",
          fontFamily: "system-ui",
          fontWeight: "900",
        }}
      >
        {cardText}
      </h2>
      <h1
        style={{
          color: "#3D608D",
          position: "absolute",
          bottom: "45px",
          right: "20px",
          fontSize: "48px",
        }}
      >
        Athens Technologies
      </h1>
      <h1
        style={{
          color: "#3D608D",
          position: "absolute",
          bottom: "-15px",
          right: "20px",
          fontSize: "48px",
        }}
      >
        Modern Cloud Development
      </h1>
    </div>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    avatar: file(absolutePath: { regex: "/athens-social-card-large.png/" }) {
      childImageSharp {
        fixed(width: 400, height: 400) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
